import React from 'react'
import styles from './style.module.less'
import { formatTime } from '@/utils/utils'

const Footer = () => {
  return (
    <div className={styles.footer}>
      {`git latest message: "${process.env.gitMessage}"`}
      {`git latest short commit: ${process.env.shortCommit}`}
      {`update time: ${formatTime(+`0x${process.env.hash}`)}`}
    </div>
  )
}

export default Footer
