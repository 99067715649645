import { GOOGLE_RECAPTCHA_KEY } from '@/config'
import { RecaptchaAction } from '@/services/wallet'
import i18next from 'i18next'

export async function getToken(action: RecaptchaAction) {
  const token = await window.grecaptcha.enterprise.execute(
    GOOGLE_RECAPTCHA_KEY,
    {
      action
    }
  )
  return token
}

export async function getGoogleRecaptchaToken(
  action: RecaptchaAction
): Promise<string> {
  return new Promise(function (resolve, reject) {
    if (!window.grecaptcha) {
      reject(i18next.t('login.loadGoogleRecaptchaFail'))
    }
    let executeTime = 0
    async function exec() {
      try {
        executeTime++
        const token = await getToken(action)
        resolve(token)
      } catch (error: any) {
        if (executeTime < 4) {
          exec()
        } else {
          reject('Fail to get google recaptcha token')
        }
      }
    }
    window.grecaptcha.enterprise.ready(exec)
  })
}

export const RecaptchaActions: Record<string, RecaptchaAction> = {
  login: 'login',
  register: 'register',
  metamaskLogin: 'metaMaskLogin',
  jwtAuthLogin: 'jwtAuthLogin',
  sendEmail: 'sendEmail'
}

export const LOGIN_DOMAIN = process.env.isTestOrDev
  ? 'http://34.87.135.238:1088'
  : 'https://passport.meet48.com'
